import { __decorate } from "tslib";
import { Component } from 'vue-property-decorator';
import BiologicalSex from '@/views/PersonalArea/medicalCard/BiologicalSex.vue';
import Birthday from '@/views/PersonalArea/medicalCard/Birthday.vue';
import MainBtn from '@/components/UI/buttons/MainBtn.vue';
import PersonalAreaMixin from '@/mixins/personal-area-mixins';
import { IRouter } from '@/interfaces/router.interface';
var ROUTE_NAME = IRouter.ROUTE_NAME;
let WelcomePage = class WelcomePage extends PersonalAreaMixin {
    async mounted() {
        await this.$store.dispatch('personalArea/init');
    }
    get pageMode() {
        return this.$route.query.pageMode;
    }
    get medCard() {
        return this.$store.state.personalArea.medicalCard;
    }
    save() {
        const { birth_date, gender } = this.medCard;
        if (!birth_date || !gender) {
            this.$store.dispatch('error/showErrorNotice', { message: 'Все поля обязательны для заполнения.' });
            return;
        }
        this.$router.push({ name: ROUTE_NAME.INDEX_PAGE });
    }
};
WelcomePage = __decorate([
    Component({
        components: {
            BiologicalSex,
            Birthday,
            MainBtn,
        },
    })
], WelcomePage);
export default WelcomePage;
